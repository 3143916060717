const beige = {
  '50': '#f9f7f3',
  '100': '#f2eee3',
  '200': '#e3dac5',
  '300': '#d2c19f',
  '400': '#bea379',
  '500': '#b18d5e',
  '600': '#a47b52',
  '700': '#886446',
  '800': '#6f523d',
  '900': '#5a4334',
  '950': '#30231a',
}

const brandGray = {
  '50': '#f6f6f6',
  '100': '#e7e7e7',
  '200': '#d1d1d1',
  '300': '#b0b0b0',
  '400': '#888888',
  '500': '#6d6d6d',
  '600': '#5d5d5d',
  '700': '#4f4f4f',
  '800': '#454545',
  '900': '#333333',
  '950': '#262626',
}

const brandPurple = {
  '50': '#f2f2fb',
  '100': '#e7e7f8',
  '200': '#d4d3f2',
  '300': '#bbb8e9',
  '400': '#a59bde',
  '500': '#9382d2',
  '600': '#8369c2',
  '700': '#6c53a4',
  '800': '#5c498a',
  '900': '#4d406f',
  '950': '#2e2541',
}

const colors = {
  beige,
  brandGray,
  brandPurple,
}

export default colors
